.base-header{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    border: none;
    .btn{
        border: none;
        color:#0267C1;
        display: flex;
        align-items: center;
        background: none;
        box-shadow: none;
    }

    #header-back-btn{
        min-width: 120px;
        justify-self: flex-start;
        justify-content: flex-start;
    }

    .back-btn{
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .text{
            display: none;
        }
    }
    
}

@media screen and (min-width: 950px) {
    .base-header{
        width: 100%;
        max-width: 75vw;
        .back-btn{
            border: 1px solid white;
            border-radius: 5px;
            padding: 5px 10px;
            .text{
                display: block;
                color: #0267C1;
            }
            &:hover{
                background-color: rgba(255,255,255,0.1);
            }
        }
        
    }
}
    
