.footer{
    position: fixed;
    width: 100%;
    bottom: 0;
    min-height: 5vh;
    padding: 4px 50px;
    background-color: white;
    border-top: 1px solid;
    border-radius: 20px 20px 0 0;
    border-color: gainsboro;
    z-index: 10;
}

.header{
    position: fixed;
    width: 100%;
    max-height: 5vh;
    min-height: 5vh;
    min-width: 100vw;
    display: flex;
    justify-content: center;
    background-color: white;
    color: #0267C1;
    max-width: 500px;
    z-index: 10;
}

.footer-text{
    display: none;
}

@media screen and (max-width: 950px){
    .header{
        padding: 0;
    }
}

@media screen and (min-width: 950px){
    .footer{
        width: 95vw;
        left: 50%;
        transform: translateX(-50%);
        background-color: white;
        .footer-text{
            display: flex;
        }
        .footer-col{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            a{
                font-style: none;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                *{
                    margin: 0 5px;
                }
            }
        }
    }

    .header{
        background-color: white;
    }
}

@media screen and (min-width: 1150px){
    .footer{
        width: 75vw;
    }
}
