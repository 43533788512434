.page-layout{
    padding: 1%;
    width: 100%;
    height: 100%;
    margin: 5vh 0vh 6vh 0vh;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
}

.no-header{
    margin-top: 0.5vh;
}


@media screen and (min-width: 950px) {
    .page-layout{
        .content{
            width: 90vw;
        }
    }
}

@media screen and (min-width: 1150px) {
    .page-layout{
        .content{
            width: 70vw;
        }
    }
}
    