

@keyframes loader {
    0% { transform: translate(6px,40px) scale(0); }
    25% { transform: translate(6px,40px) scale(0); }
    50% { transform: translate(6px,40px) scale(1); }
    75% { transform: translate(40px,40px) scale(1); }
   100% { transform: translate(74px,40px) scale(1); }
 }
 @keyframes loader-r {
    0% { transform: translate(74px,40px) scale(1); }
    100% { transform: translate(74px,40px) scale(0); }
 }
 @keyframes loader-c {
    0% { background: #18D1EF }
   25% { background: #0267C1 }
   50% { background: #18D1EF }
   75% { background: #0267C1 }
  100% { background: #18D1EF }
 }
 .loader div {
   position: absolute;
   width: 20px;
   height: 20px;
   border-radius: 50%;
   transform: translate(80px,80px) scale(1);
   background: #e15b64;
   animation: loader 2s infinite cubic-bezier(0,0.5,0.5,1);
 }
 .loader div:nth-child(1) {
   background: #18D1EF;
   transform: translate(148px,80px) scale(1);
   animation: loader-r 0.5s infinite cubic-bezier(0,0.5,0.5,1), loader-c 1s infinite step-start;
 }.loader div:nth-child(2) {
   animation-delay: -0.5s;
   background: #0267C1;
 }.loader div:nth-child(3) {
   animation-delay: -1s;
   background: #18D1EF;
 }.loader div:nth-child(4) {
   animation-delay: -1.5s;
   background: #0267C1;
 }.loader div:nth-child(5) {
   animation-delay: -2s;
   background: #18D1EF;
 }
 .loader-div {
   width: 50px;
   height: 50px;
   display: inline-block;
   overflow: hidden;
   background: #ffffff;
 }
 .loader {
   background-color: invisible;
   background: none;
   width: 100%;
   height: 100%;
   position: relative;
   transform: translateZ(0) scale(0.5);
   backface-visibility: hidden;
   transform-origin: 0 0; /* see note above */
 }
 .loader div { box-sizing: content-box; }

