

.cookie-consent-overlay{
    position: fixed; 
    left: 0px; 
    top: 0px; 
    width: 100%; 
    height: 100%; 
    z-index: 999; 
    background-color: rgba(0, 0, 0, 0.3);
}

.cookie-consent-container{
    height: max-content;
    width: 100%; 
    border-radius: 20px 20px 0 0;
    background-color: rgb(0, 0, 0);
    background: rgb(53, 53, 53); 
    color: white; 
    display: flex; 
    align-items: center; 
    justify-content: start;
    flex-wrap: wrap;
    position: fixed;
    left: 0px; 
    bottom: 0px; 
    z-index: 999;
    opacity: 0.9;
    
    .cookie-consent-content{
        flex: 1 0 700px; 
        width: 100%;
        margin: 0;
        padding: 20px;
        display: flex;
        align-items: start;
        justify-content: center;
        .content-row{
            width: 100%;

        }
    }
    .cookie-consent-btn-wrapper{
        width: 100%;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        .cookie-consent-btn{
            height: 100%;
            opacity: 1;
            background-color: bisque;
            color: black;
            border: 1px solid rgb(175, 152, 124);
            border-radius: 10px;
            padding: 10px 20px;
            &:hover{
                background-color: rgb(175, 152, 124);
            }
            cursor: pointer;
            width: max(200px, 40%);
        }
        .cookie-consent-btn-cancel{
            height: 100%;

            background-color: rgb(255, 193, 118);
            border: 1px solid rgb(175, 152, 124);
            color: #000;
            &:hover{
                background-color: rgb(175, 152, 124);
            }
            opacity: 1;
            border-radius: 10px;
            padding: 10px 20px;
            cursor: pointer;
            width: max(200px, 40%);
            

        }
    }
}


@media screen and (max-width: 768px){

    .cookie-consent-container{
        .cookie-consent-content{
            flex: 1 0 100%;
            .content-row{
                width: 100%;
            }
        }
    }
}
