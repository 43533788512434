.error-page-content{
    height: 100vh;
    max-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .error-page-container {
      max-height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f0f2f5;
      .ant-result{
        padding: 10px;
      }
      
        .ant-result-title {
          color: #1890ff;
          font-size: 36px;
          font-weight: bold;
        }
        .ant-result-subtitle {
          font-size: 24px;
          margin-top: 16px;
        }
      
        .ant-btn-primary {
          background-color: #1890ff;
          border-color: #1890ff;
          margin-top: 24px;
        }
      
        .ant-btn-primary:hover,
        .ant-btn-primary:focus,
        .ant-btn-primary:active {
          background-color: #40a9ff;
          border-color: #40a9ff;
        }



        .reload-btn{
            border-radius: 20px;
            padding: 10px;
            width: 100%;
            height: 100%;
        }
    }
  
  
  }